import { render, staticRenderFns } from "./UploadPrayerCalendar.vue?vue&type=template&id=3dcff240&scoped=true&"
import script from "./UploadPrayerCalendar.vue?vue&type=script&lang=js&"
export * from "./UploadPrayerCalendar.vue?vue&type=script&lang=js&"
import style0 from "./UploadPrayerCalendar.vue?vue&type=style&index=0&id=3dcff240&prod&lang=scss&scoped=true&"
import style1 from "./UploadPrayerCalendar.vue?vue&type=style&index=1&id=3dcff240&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dcff240",
  null
  
)

export default component.exports